import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { FiCheckCircle, FiXOctagon } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { H4 } from '../components/Typography';
import { requestVerification as requestVerificationAction } from '../lib/actions/me';
import { getBodyMessage } from '../utils/function-utils';
import { HelpText } from './styles';

const Container = styled.div`
  max-width: 820px;
  padding: 60px 50px;
`;

type FormValues = { email: string };

export const RequestVerificationForm: FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
  });
  const dispatch = useDispatch();

  async function requestVerification(email: string) {
    try {
      await dispatch(requestVerificationAction(email));
      toast.success('Verification email was sent again', {
        icon: <FiCheckCircle className="min-w-full w-8" size={32} color="#3CB043" />,
      });
    } catch (err) {
      toast.error(getBodyMessage(err), {
        icon: <FiXOctagon className="min-w-full w-8" size={32} color="#D0312D" />,
      });
    }
  }

  const onSubmit = async (values: FormValues) => {
    await requestVerification(values.email);
  };

  return (
    <form onSubmit={handleSubmit<FormValues>(onSubmit)} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full">
        <H4 className="mb-6 text-center">Re-send your verification email</H4>

        <Input
          label="Email"
          className="mb-4"
          error={!!errors.email}
          helpText={
            errors.email && (
              <HelpText>
                {errors.email?.type === 'required' ? 'Email is required' : 'Email is invalid'}
              </HelpText>
            )
          }
          inputStyle={{ color: '#333' }}
          {...register('email', {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })}
        />

        <div className="flex justify-end mt-4">
          <Button type="submit" disabled={isSubmitting}>
            Send email
          </Button>
        </div>
      </Container>
    </form>
  );
};
